import React from 'react';
import ClassNames from 'classnames';

import {createStyles, withStyles, WithStyles,} from '@material-ui/core/styles';
import {Grid} from '@material-ui/core';

const styles = () => createStyles({
  wrap: {
    padding: 24,
    width: '100%',
  },
  maxWidth: {
    maxWidth: 1055,
  },
});

interface IProps extends WithStyles<typeof styles> {
  children: React.ReactNode;
  fullWidth?: boolean;
  noPadding?: boolean;
  direction?: 'column' | 'column-reverse' | 'row' | 'row-reverse';
  spacing?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
}

const PageContentWrap: React.FC<IProps> = ({
  classes,
  children,
  noPadding,
  fullWidth,
  direction,
  spacing,
}) => (
  <div className={ClassNames({[classes.wrap] : !noPadding}, { [classes.maxWidth]: !fullWidth })}>
    <Grid
      direction={direction || 'row'}
      container
      spacing={spacing || 3}
    >
      {children}
    </Grid>
  </div>
);

export default withStyles(styles)(PageContentWrap);
