import React, {Fragment} from 'react';
import wrapSvgPath from './wrapSvgPath';

const integrationsIcon = (
    <Fragment>
        <path
            d="M26.448 19.2513L28.214 20.2123C28.3209 20.2693 28.4112 20.353 28.476 20.4553C28.5384 20.5535 28.5715 20.6675 28.5715 20.7838C28.5715 20.9001 28.5384 21.0141 28.476 21.1123C28.4112 21.2146 28.3209 21.2983 28.214 21.3553L15.328 28.3653C15.0954 28.4901 14.8355 28.5554 14.5715 28.5554C14.3076 28.5554 14.0477 28.4901 13.815 28.3653L0.929047 21.3553C0.822162 21.2983 0.731887 21.2146 0.667047 21.1123C0.604702 21.0141 0.571596 20.9001 0.571596 20.7838C0.571596 20.6675 0.604702 20.5535 0.667047 20.4553C0.731887 20.353 0.822162 20.2693 0.929047 20.2123L2.70005 19.2513L14.577 25.7123L26.448 19.2513ZM26.448 12.9903L28.214 13.9513C28.3209 14.0083 28.4112 14.092 28.476 14.1943C28.5384 14.2925 28.5715 14.4065 28.5715 14.5228C28.5715 14.6391 28.5384 14.7531 28.476 14.8513C28.4112 14.9536 28.3209 15.0373 28.214 15.0943L14.572 22.5153L0.929047 15.0893C0.822162 15.0323 0.731887 14.9486 0.667047 14.8463C0.604702 14.7481 0.571596 14.6341 0.571596 14.5178C0.571596 14.4015 0.604702 14.2875 0.667047 14.1893C0.731887 14.087 0.822162 14.0033 0.929047 13.9463L2.70005 12.9893L14.577 19.4503L26.448 12.9903ZM15.327 0.745291L28.214 7.75529C28.3209 7.81226 28.4112 7.89599 28.476 7.99829C28.5384 8.09652 28.5715 8.21045 28.5715 8.32679C28.5715 8.44313 28.5384 8.55707 28.476 8.65529C28.4112 8.75759 28.3209 8.84132 28.214 8.89829L14.572 16.3203L0.929047 8.89929C0.822162 8.84232 0.731887 8.75859 0.667047 8.65629C0.604586 8.55811 0.571411 8.44416 0.571411 8.32779C0.571411 8.21143 0.604586 8.09747 0.667047 7.99929C0.731887 7.89699 0.822162 7.81326 0.929047 7.75629L13.815 0.746291C14.0477 0.621473 14.3076 0.556152 14.5715 0.556152C14.8355 0.556152 15.0954 0.621473 15.328 0.746291L15.327 0.745291ZM14.572 3.44529L5.59005 8.32729L14.572 13.2143L23.554 8.32729L14.572 3.44529Z"
            fill="#323232" fillOpacity="0.9"/>
    </Fragment>
);

export default wrapSvgPath(integrationsIcon, '0 0 28 28');
