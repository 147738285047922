import React from 'react';

import {createStyles, withStyles, WithStyles,} from '@material-ui/core/styles';

const styles = () => createStyles({
  toolbarWrap: (props: { isBackButton?: boolean }) => ({
    display: 'flex',
    gap: 40,
    flex: 1,
    padding: '0 24px',
    height: 64,
    background: '#F6F9F9',
    justifyContent: props.isBackButton ? 'flex-start' : 'space-between',
    alignItems: 'center',
    // boxShadow: '0 2px 6px 0 rgba(31, 48, 63, 0.5)',
  }),
});

interface IProps extends WithStyles<typeof styles> {
  children: React.ReactNode;
  isBackButton?: boolean;
}

const PageHeaderWrap: React.FC<IProps> = ({ classes, children }) => (
  <div className={classes.toolbarWrap}>
    { children }
  </div>
);

export default withStyles(styles)(PageHeaderWrap);
