import _ from 'lodash';
import {
  put, all, select, takeEvery, takeLatest, call,
} from 'redux-saga/effects';
import * as Sentry from '@sentry/browser';

import {
  cashBoxConnectStatusReq,
  checkRestaurantFromIikoReq,
  createRestaurantReq,
  getAllRestaurantReq,
  getRestaurantPluginsInfoReq,
} from '../api';
import {
  setCurrentRestaurant,
  getRestaurantError,
  getRestaurantListSuccess,
  checkRestaurantFromIiko,
  updateRestaurantList,
  addRestaurantError,
  needAuthFromIiko,
  getCashBoxConnectStatusSuccess,
  getCashBoxConnectStatusError,
  getCashBoxPluginsStatusSuccess,
  getCashBoxPluginsStatusError,
  addRestaurantSuccess,
  initFinished,
  getCashBoxPluginsStatus,
  getCashBoxConnectStatus,
  showWarning,
  hideWarning,
} from '../actions/cabinet/CabinetActions';
import {
  UPDATE_RESTAURANT_INFO_SUCCESS,
} from '../actions/restaurantsInfo/RestaurantsInfoActionsTypes';
import { DELETE_RESTAURANT_SUCCESS } from '../actions/restaurant/RestaurantsActionTypes';
import {
  CONNECT_RESTAURANT_SUCCESS,
  SELECT_ORGANIZATION_SUCCESS,
} from '../actions/home/HomeActionTypes';
import {
  CHECK_RESTAURANT_FROM_IIKO_REQUEST,
  GET_RESTAURANT_LIST_REQUEST,
  CREATE_RESTAURANT_REQUEST,
  CASHBOX_CONNECT_STATUS_REQUEST,
  CASHBOX_PLUGIN_STATUS_REQUEST,
  SET_CURRENT_RESTAURANT,
  INIT_START,
  GET_RESTAURANT_LIST_SUCCESS,
} from '../actions/cabinet/CabinetActionsTypes';
import { TRestaurant } from '../types/ICabinetStore';
import {
  IGetCashBoxPluginsStatus,
} from '../actions/cabinet/ICabinetActions';
import { getScheduleList } from '../actions/schedules/SchedulesAction';
import {
  isCashBoxUpdateRequired,
} from '../containers/CashBoxWarning/selectors';
import {selectorRestaurantId} from "../selectors/RestaurantSelectors";

/** @description Инициилазиция личного кабинета, собираем все данные нужные для работы */
function* initCabinetSaga() {

  try {
    Notification.requestPermission(result => result);
  } catch (e) {
    console.error('request notification error');
  }

  try {
    const { content }: { content: Array<TRestaurant> } = yield call(getAllRestaurantReq);

    if (content.length > 0) {
      // const restaurantId = content[0].id;

      yield put(getRestaurantListSuccess(content));
      yield put(getScheduleList());


      // Убрали в рамках задачи DEV-244
      // yield put(checkRestaurantFromIiko(restaurantId));

      // Убираю, так как проверка идет при получении списка ресторанов и выбора ресторана по умолчанию
      // yield put(getCashBoxPluginsStatus(restaurantId));
      // yield put(getCashBoxConnectStatus(restaurantId));
    }
  } catch (error) {
    yield put(getRestaurantError(error));
  }

  yield put(initFinished());
}

/** @description Сага для получение списка всех доступных ресторанов для юзера */
function* getRestaurantListSaga() {
  try {
    const { content }: { content: Array<TRestaurant> } = yield call(getAllRestaurantReq);
    yield put(getRestaurantListSuccess(content));
  } catch (error) {
    yield put(getRestaurantError(error));
  }
}

function* setSentryContext() {
  const restaurant = yield select(state => state.cabinet.currentRestaurant);

  if (restaurant) {
    const { id, name } = restaurant;

    Sentry.setContext('restaurant', {
      id,
      name,
    });
  } else {
    Sentry.setContext('restaurant', null);
  }
}

/** @description Сага для добавления нового ресторана */
function* addRestaurantSaga(action: any) {
  try {
    const restaurantData = action.payload;
    const response = yield call(createRestaurantReq, restaurantData);
    yield put(addRestaurantSuccess());
    yield put(updateRestaurantList(response.data));
    yield put(setCurrentRestaurant(response.data.id));
    yield put(checkRestaurantFromIiko(response.data.id));
  } catch (e) {
    yield put(addRestaurantError());
  }
}

/**
 * @description Сага для проверки привязки ресторана к иико.
 * Если ответ не 200 - считаем что ресторан не привязан и требуется авторизация/привязка
 */
function* checkRestaurantFromIikoSaga(action: any) {
  try {
    const restaurantList = yield select(state => state.cabinet.restaurantList);
    const restaurantInfo = _.find(restaurantList, { id: action.payload.restaurantId }).settings;

    const response = yield call(checkRestaurantFromIikoReq, action.payload.restaurantId);
    if (response.status !== 200 || !restaurantInfo.iikoOrganizationId) {
      yield put(needAuthFromIiko());
    }
  } catch (e) {
    yield put(needAuthFromIiko());
  }
}

/** @description Сага для запроса статуса кассы */
function* getCashBoxConnectStatusSaga(action: any) {
  let restaurantId = yield select(selectorRestaurantId);

  if (action.type === CASHBOX_CONNECT_STATUS_REQUEST && action.payload.restaurantId) {
    restaurantId = action.payload.restaurantId;
  }

  try {
    const cashBoxStatus = yield call(cashBoxConnectStatusReq, restaurantId);
    yield put(getCashBoxConnectStatusSuccess(cashBoxStatus.active));
  } catch (e) {
    // 404 - касса не настроена, показываем виджет что нужно настроить кассу Шаг.2
    if (e.response.status === 404) {
      yield put(getCashBoxConnectStatusSuccess());
    } else yield put(getCashBoxConnectStatusError());
  }
}

/** @description Сага для запроса статуса плагинов кассы */
function* getCashBoxPluginsStatusSaga(action: IGetCashBoxPluginsStatus) {
  let restaurantId = yield select(selectorRestaurantId);

  if (action.type === CASHBOX_PLUGIN_STATUS_REQUEST && action.payload.restaurantId) {
    restaurantId = action.payload.restaurantId;
  }

  try {
    const info = yield getRestaurantPluginsInfoReq(restaurantId);
    yield put(getCashBoxPluginsStatusSuccess(info));
    if (yield select(isCashBoxUpdateRequired)) yield put(showWarning());
  } catch (e) {
    yield put(getCashBoxPluginsStatusError());
    yield put(hideWarning());
  }
}

export default function* saga() {
  yield all([
    takeEvery(INIT_START, initCabinetSaga),
    takeEvery([
      GET_RESTAURANT_LIST_REQUEST,
      DELETE_RESTAURANT_SUCCESS,
      SELECT_ORGANIZATION_SUCCESS,
      CONNECT_RESTAURANT_SUCCESS,
      UPDATE_RESTAURANT_INFO_SUCCESS,
    ], getRestaurantListSaga),
    takeLatest(CREATE_RESTAURANT_REQUEST, addRestaurantSaga),
    takeLatest(CHECK_RESTAURANT_FROM_IIKO_REQUEST, checkRestaurantFromIikoSaga),

    takeLatest(
      [
        CASHBOX_CONNECT_STATUS_REQUEST,
        SET_CURRENT_RESTAURANT,
        GET_RESTAURANT_LIST_SUCCESS,
      ],
      getCashBoxConnectStatusSaga,
    ),

    takeLatest(
      [
        CASHBOX_PLUGIN_STATUS_REQUEST,
        SET_CURRENT_RESTAURANT,
        GET_RESTAURANT_LIST_SUCCESS,
      ],
      getCashBoxPluginsStatusSaga,
    ),

    takeLatest([
      GET_RESTAURANT_LIST_SUCCESS,
      SET_CURRENT_RESTAURANT,
    ], setSentryContext),
  ]);
}
