import React, {Fragment} from 'react';
import wrapWithPath from './wrapSvgPath';

const BackIcon = (
    <Fragment>
            <path
                d="M7.828 10.9999H20V12.9999H7.828L13.192 18.3639L11.778 19.7779L4 11.9999L11.778 4.22192L13.192 5.63592L7.828 10.9999Z"
                fill="#323232"/>
    </Fragment>
);

export default wrapWithPath(BackIcon, '0 0 24 24');
