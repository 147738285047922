import React, {ReactNode} from 'react';
import {createStyles, Theme, WithStyles, withStyles,} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

const styles = (theme: Theme) => createStyles({
  layout: {
    width: 'auto',
    height: '100vh',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 600,
    maxWidth: 600,
    padding: '60px 24px 16px',
    [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
      padding: '60px 64px 16px',
    },
    borderRadius: 10,
    flex: 1,
  },
});

interface IProps extends WithStyles<typeof styles>{
  children: ReactNode;
}

const AuthWrapComp: React.FC<IProps> = ({ classes, children }) => (
  <div className="imgWrap">
    <main className={classes.layout}>
      <Paper elevation={3} className={classes.paper}>
        { children }
      </Paper>
    </main>
  </div>
);

export default withStyles(styles)(AuthWrapComp);
