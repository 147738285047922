import React, {Fragment} from 'react';
import wrapSvgPath from './wrapSvgPath';

const settingsIconPath = (
    <Fragment>

        <path id="statistic" fill="#323232" fillRule="evenodd"
              d="M20.677 1.077A1.077 1.077 0 0 1 21.754 0h5.169A1.077 1.077 0 0 1 28 1.077v25.846A1.077 1.077 0 0 1 26.923 28h-5.169a1.077 1.077 0 0 1-1.077-1.077zm2.154 1.077v23.692h3.015V2.154zm-12.493 9.261a1.077 1.077 0 0 1 1.077-1.077h5.169a1.077 1.077 0 0 1 1.077 1.077v15.508A1.077 1.077 0 0 1 16.585 28h-5.17a1.077 1.077 0 0 1-1.077-1.077zm2.154 1.077v13.354h3.015V12.492zM0 21.754a1.077 1.077 0 0 1 1.077-1.077h5.169a1.077 1.077 0 0 1 1.077 1.077v5.169A1.077 1.077 0 0 1 6.246 28H1.077A1.077 1.077 0 0 1 0 26.923zm2.154 1.077v3.015h3.015v-3.015z"/>

    </Fragment>
);

export default wrapSvgPath(settingsIconPath, '0 0 28 28');
