import React from 'react';

import {createStyles, withStyles, WithStyles,} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

import locales from '../../../locales';
import Title from '../../Common/Title';
import EditField from '../../Common/SelfSavingFields/EditField';
import FieldWrap from '../../Common/FieldWrap';
import {cardFieldType} from '../../../config/constants';
import ScheduleTable from '../../Common/ScheduleTable';
import {TRestaurant} from '../../../types/ICabinetStore';
import {IFreeDay, IScheduleRow, TWeekDay} from '../../../types/IScheduleStore';

const styles = () => createStyles({
  contentWrap: {
    padding: '12px 0',
  },
});

interface IProps extends WithStyles<typeof styles>{
  scheduleList: Array<IScheduleRow>;
  freeDays: Array<IFreeDay>;
  currentRestaurant: TRestaurant;
  updateRestaurant: (arg: {
    value: string | number, initValue: string, type: any
  }) => void;
  handleAddScheduleRow: () => void;
  handelUpdateSchedule: (newScheduleInfo: any) => void;
  handleDeleteScheduleRow: (row: IScheduleRow) => void;
  handleUpdateFreeDays: (days: {
    activate: Array<TWeekDay>,
    deactivate: Array<TWeekDay>
  }) => void;
}

const Index: React.FC<IProps> = ({
  classes,
  currentRestaurant,
  updateRestaurant,
  scheduleList,
  handleAddScheduleRow,
  handelUpdateSchedule,
  handleDeleteScheduleRow,
  handleUpdateFreeDays,
  freeDays,
}) => (
  <Paper elevation={3}>
    <Title borderBottom text={locales.t('reservesSystem.basicInformation.title')} />
    <div className={classes.contentWrap}>
      <FieldWrap>
        <EditField
          label={locales.t('reservesSystem.basicInformation.name')}
          value={currentRestaurant.name}
          handleChangeList={updateRestaurant}
          type={cardFieldType.name}
          maxLength={255}
        />
      </FieldWrap>
      <FieldWrap>
        <EditField
          label={locales.t('reservesSystem.basicInformation.address')}
          value={`${currentRestaurant.address.country}, ${currentRestaurant.address.city}, ${currentRestaurant.address.street}, ${currentRestaurant.address.home}`}
          handleChangeList={updateRestaurant}
          type={cardFieldType.about}
          disabled
        />
      </FieldWrap>
      <ScheduleTable
        label={locales.t('reservesSystem.basicInformation.scheduleList')}
        scheduleList={scheduleList}
        handleAddScheduleRow={handleAddScheduleRow}
        handelUpdateSchedule={handelUpdateSchedule}
        handleDeleteScheduleRow={handleDeleteScheduleRow}
        handleUpdateFreeDays={handleUpdateFreeDays}
        freeDays={freeDays}
      />
    </div>
  </Paper>
);

export default withStyles(styles)(Index);
