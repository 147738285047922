import React, {FC, Fragment, useCallback} from 'react';
import {withStyles} from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {useHistory} from 'react-router-dom';

import {Routers} from '../config/constants';
import locales from '../locales';
import PageHeaderWrap from '../components/Common/PageHeaderWrap';
import CashBoxWarningCont from '../containers/CashBoxWarning';
import {NoHostessWarning} from '../features';
import TelephonyIntegrationsPage from "./TelephonyIntegrationsPage";
import GatewaysIntegrationsPage from "./GatewaysIntegrationsPage";
import BackIcon from "../components/Common/Icons/BackIcon";

const StyledTabs = withStyles({
    root: {
        minHeight: 64,
    },
})(Tabs);

const StyledTab = withStyles({
    root: {
        minWidth: 180,
        fontSize: '16px',
        minHeight: 64,
    },
})(Tab);


type PageID = 'gateways' | 'telephony'

const routeMap: {
    [view: string]: string
} = {
    gateways: Routers.gateways,
    telephony: Routers.telephony,
};

interface SettingsPageProps {
    view: PageID;
}

const IntegrationsPage: FC<SettingsPageProps> = ({ view }) => {
    const history = useHistory();

    const onTabChange = useCallback((event: any, value: string) => {
        if (routeMap[value]) {
            history.push(routeMap[value]);
        }
    }, []);

    return (
        <Fragment>
                <PageHeaderWrap isBackButton>
                    {history.location.pathname.split('/')[2] && <BackIcon onClick={() => history.push('/gateways')} style={{cursor: 'pointer'}} /> }
                    <StyledTabs
                        onChange={onTabChange}
                        scrollButtons="off"
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                        centered
                        value={view}
                    >
                        <StyledTab label={locales.t('gateways.title')} value="gateways" />
                        {/*<StyledTab label={locales.t('telephony.title')} value="telephony" />*/}
                    </StyledTabs>
                </PageHeaderWrap>

            <CashBoxWarningCont />
            <NoHostessWarning />

            {view === 'gateways' && <GatewaysIntegrationsPage />}
            {view === 'telephony' && <TelephonyIntegrationsPage />}
        </Fragment>
    );
};

export const GatewaysPage: FC = () => (
    <IntegrationsPage view="gateways" />
);

export const TelephonyPage: FC = () => (
    <IntegrationsPage view="telephony" />
);


