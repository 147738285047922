import {createStyles} from '@material-ui/core';

export default createStyles({
  paper: {
    width: 370,
    height: 310,
  },
  paperEdit: {
    width: 416,
    height: 290,
  },
  desc: {
    margin: 0,
    display: 'flex',
    flexDirection: 'column',
    gap: 16
  },
  dialogActionsWrap: {
    padding: '16px 24px',
    display: 'flex',
  },
  btnWrapClose: {
    height: 36,
    flex: '1 1 auto'
  },
  btnWrapDelete: {
    flex: '1 1 auto',
    maxWidth: 210,
    background: '#F45858',
    width: 'fit-content',
    height: 36,
  },
  btnWrapSuccess: {
    flex: '1 1 auto',
    maxWidth: 210,
    background: '#6AE9C5',
    width: 'fit-content',
    height: 36,
  }
});
