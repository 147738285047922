import React from 'react';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Typography,
    withStyles,
    WithStyles
} from '@material-ui/core';
import styles from './styles';
import DialogTitle from "../../../components/Common/DialogTitle";

interface IProps extends WithStyles<typeof styles> {
    isOpen: boolean;
    handleClose: () => void;
    handleDelete: () => void;
}

const WidgetDelete: React.FC<IProps> = ({
    classes,
    isOpen,
    handleClose,
    handleDelete,
    }) => {

    return (
        <Dialog
            classes={{ paper: classes.paperEdit }}
            open={isOpen}
        >
            <DialogTitle onClose={handleClose}>
                Удалить интеграцию?
            </DialogTitle>
            <DialogContent dividers>
                <DialogContentText className={classes.desc}>
                    <Typography variant="body1" color="textPrimary">
                        Вы уверены, что хотите удалить интеграцию?
                    </Typography>
                    <Typography variant="body1" color="textPrimary">
                        Если это единственный подключенный СМС-шлюз, СМС-сообщения перестанут отправляться.
                    </Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions className={classes.dialogActionsWrap}>
                <Button
                    className={classes.btnWrapClose}
                    variant="contained"
                    color="secondary"
                    onClick={handleClose}
                >
                    Отмена
                </Button>
                <Button
                    className={classes.btnWrapDelete}
                    variant="contained"
                    onClick={handleDelete}
                >
                    Подтвердить удаление
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default withStyles(styles)(WidgetDelete);
