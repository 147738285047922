import React, {FC, useEffect, useState} from 'react'
import {Button, createStyles, Link, Switch as SwitchUI, Typography, WithStyles, withStyles} from "@material-ui/core";
import MenuAddLineIcon from "../components/Common/Icons/MenuAddLineIcon";
import smsAero from '../images/smsAero.png'
import smsCenter from '../images/smsCenter.png'
import playMobile from '../images/playMobile.png'
import {Route, RouteComponentProps, Switch, useHistory, withRouter} from "react-router-dom";
import {Routers} from "../config/constants";
import PageContentWrap from "../components/Common/PageContentWrap";
import {Gateway, WidgetDisable, WidgetEnable} from "../containers/Gateways";
import {connect} from "react-redux";
import {IAppStore} from "../types/IAppStore";
import {IGateway, TRestaurant} from "../types/ICabinetStore";
import {updateRestaurantInfo} from "../actions/restaurantsInfo/RestaurantsInfoActions";
import {bindActionCreators, Dispatch} from "redux";

interface IMapsToPops {
    currentRestaurant?: TRestaurant;
}

const mapStateToProps = (state: IAppStore): IMapsToPops => {
    return {
        currentRestaurant: state.cabinet.currentRestaurant
    };
};

const styles = createStyles({
    contentTitle: {
        marginTop: 34,
        fontSize: 20,
        lineHeight: '24px'
    },
    container: {
        width: '100%',
        paddingTop: 24,
        paddingLeft: 12,
        paddingRight: 12,
        paddingBottom: 24,
        flexDirection: 'column',
        display: 'flex',
        gap: 16,
    },
    title: {
        display: "flex",
        alignItems: "center",
        gap: 16,
        color: "#32323240",
        fontWeight: 600,
        fontSize: 16,
        opacity: 25,
    },
    titleLineLeft: {
        width: 24,
        height: 1,
        backgroundColor: '#32323229',
    },
    titleLineRight: {
        width: '100%',
        height: 1,
        backgroundColor: '#32323229',
    },
    row: {
        display: 'flex',
        flexDirection: 'column',
        gap: 24,
    },
    empty: {
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
        maxWidth: 476,
        borderRadius: 4,
        marginLeft: 24,
        paddingTop: 24,
        paddingBottom: 24,
        alignItems: 'center',
        border: '1px solid #CACDD0',
        width: '100%',
        color: '#BABDBF'
    },
    emptyIcon: {
        width: 48,
        height: 48,
    },
    list: {
        display: 'flex',
        gap: 24,
        marginLeft: 24,
        flexWrap: 'wrap',
        overflowX: 'auto',
    },
    item: {
        display: 'flex',
        gap: 24,
        flexDirection: 'column',
        padding: 24,
        boxShadow: '0 3px 6px 0 rgba(31, 48, 63, 0.5)',
        flex: '0 0 calc(50% - 24px)',
        backgroundColor: '#fff',
        borderRadius: 4,
        minWidth: 400,
    },
    contentBtn: {
        height: 37,
        width: 134,
        fontFamily: 'inherit',
        boxShadow: '0 2px 2px 0 rgba(50, 50, 50, 0.25)',
    },
    itemBody: {
        flexDirection: 'column',
        display: "flex",
        gap: 8,
        justifyContent: 'space-between',
        maxHeight: 96,
    },
    itemBodySelect: {
        flexDirection: 'column',
        display: "flex",
        gap: 8,
        width: '100%',
        justifyContent: 'center',
        maxHeight: 96,
    },
    itemIcon: {
        display: 'flex',
        backgroundColor: '#F7F8FA',
        alignItems: 'center',
        justifyContent: 'center',
        width: 96,
        height: 96,
        minWidth: 96,
        borderRadius: 8,
        border: '1px solid #E7EBEE',
    },
    itemContent: {
        display: 'flex',
        gap: 16,
    },
    icon: {
        width: '100%',
        height: 'auto',
        maxWidth: 64,
    },
    status: {
        fontSize: 10,
        textTransform: 'uppercase',
        padding: '4px 8px',
        backgroundColor: '#99EDD5',
        color: '#0E4737',
        borderRadius: 16,
        width: 'fit-content',
    },
    panel: {
        width: '100%',
        padding: '16px 0',
        marginTop: -8,
        boxShadow: '0 3px 6px 0 rgba(31, 48, 63, 0.5)',
        background: 'linear-gradient(90deg, #fff8e6 0%, #fff 100%)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '@media (max-width: 520px)': {
            display: 'none',
        }
    },
    panelBody: {
        display: 'flex',
        padding: '0 24px',
        flexDirection: 'column',
        gap: 8
    },
    panelDivider: {
        width: 1,
        height: '100%',
        backgroundColor: '#323232',
        opacity: 0.5,
    },
    panelButton: {
        padding: '0 48px',
        height: '100%',
        outline: 'none !important',
        border: 'none !important',
        background: 'none !important',
        cursor: 'pointer',
    }
})

interface IDispatchToProps {
    updateRestaurantInfo: typeof updateRestaurantInfo;
}

interface IProps extends WithStyles<typeof styles>, IMapsToPops, RouteComponentProps, IDispatchToProps {}

const GatewaysContainer: React.FC<IProps> = ({ classes, currentRestaurant,updateRestaurantInfo }) => {
    const history = useHistory();
    const [currentGateways, setCurrentGateways] = useState<IGateway[]>([]);
    const [currentGatewayTypes, setCurrentGatewayTypes] = useState<string[]>([]);
    const [isOpenWidgetDisable, setIsOpenWidgetDisable] = useState<boolean>(false);
    const [isOpenWidgetEnable, setIsOpenWidgetEnable] = useState<boolean>(false);
    const [isOpenPanel, setIsOpenPanel] = useState<boolean>(false);
    const [disabledGateway, setDisableGateway] = useState<string>('');
    const [enabledGateway, setEnabledGateway] = useState<string>('');
    const handleClick = (type: string) => history.push(`/gateways/${type}`);

    const gateways = [
        {type: 'SMS_AERO', title: 'SMS Aero', description:'Популярный российский веб-сервис для рассылки СМС сообщений по всему миру', site: 'https://smsaero.ru', image: smsAero},
        {type: 'SMSC', title: 'SMS-центр', description:'Удобный стабильный сервис для СМС-рассылок по всему миру', site: 'https://smsc.ru', image: smsCenter}
        //{type: 'PLAY_MOBILE', title: 'Play Mobile (Uzbekistan)', description:'SMS-уведомления и СМС-информирование для клиентов по Узбекистану', site: 'https://playmobile.uz', image: playMobile},
    ]

    useEffect(() => {
       if (currentRestaurant) {
           if (!currentRestaurant.settings.smsNotificationSettings.gateways.length) {
               setIsOpenPanel(true)
           } else {
               setIsOpenPanel(false)
           }
           setCurrentGateways(currentRestaurant.settings.smsNotificationSettings.gateways.map(item => item))
           setCurrentGatewayTypes(currentRestaurant.settings.smsNotificationSettings.gateways.map(item => item.type))
       }
    }, [currentRestaurant]);

    const onToggleEnabled = (type: string) => {
        const currentGateway = currentGateways.find(gateway => gateway.type === type) as IGateway;
        if (currentGateway.isEnabled) {
            setIsOpenWidgetDisable(true)
            setDisableGateway(type)
        } else {
            setIsOpenWidgetEnable(true)
            setEnabledGateway(type)
        }
    }

    const disableGateway = () => {
        const isEnabled = currentGateways.filter(item => !item.isEnabled).length !== (currentGateways.length - 1);
        const currentGateway = currentGateways.find(gateway => gateway.type === disabledGateway) as IGateway;
        const updateGateway = {
            ...currentGateway,
            isEnabled: false,
        };
        const updatedGateways = [...currentGateways.filter(item => item.type !== disabledGateway), updateGateway];

        // @ts-ignore
        updateRestaurantInfo({ gateways: updatedGateways, isEnabled: isEnabled, currentRestaurant, type: 'smsNotificationSettings' });
        setDisableGateway('')
    }

    const enableGateway = () => {
        const currentGateway = currentGateways.find(gateway => gateway.type === enabledGateway) as IGateway;
        const updateGateway = {
            ...currentGateway,
            isEnabled: true,
        };
        const updatesGateway = currentGateways
            .filter(item => item.type !== enabledGateway)
            .map(item => ({
                ...item,
                isEnabled: false
            }));
        const updatedGateways = [...updatesGateway, updateGateway];

        // @ts-ignore
        updateRestaurantInfo({ gateways: updatedGateways, isEnabled: true, currentRestaurant, type: 'smsNotificationSettings' });
        setEnabledGateway('')
    }

    return (
        <div className={classes.container}>
            {currentRestaurant && !currentGateways.length && isOpenPanel && <div className={classes.panel}>
                <div className={classes.panelBody}>
                    <Typography variant="subtitle1" style={{
                        fontWeight: 'bold'
                    }}>
                        Интеграция с СМС-шлюзом позволяет отправлять гостям СМС-сообщения
                    </Typography>
                    <Typography variant="body1" style={{
                        lineHeight: 1.1,
                        opacity: 0.8
                    }}>
                        Вы сможете отправлять гостям СМС-сообщения (подтверждения резервов, напоминания и др.). Для настройки вам необходимо иметь договор с одним из поддерживаемых GuestMe шлюзов и настроить интеграцию.
                    </Typography>
                </div>
                <div className={classes.panelDivider}/>
                <button className={classes.panelButton} onClick={() => setIsOpenPanel(false)}>
                    <Typography variant="body1">
                        Скрыть
                    </Typography>
                </button>
            </div>}
            <div className={classes.row}>
                <div className={classes.title}>
                    <div className={classes.titleLineLeft}/>
                    <span>Подключенные</span>
                    <div className={classes.titleLineRight}/>
                </div>
                {currentGateways.length > 0 ? (
                    <div className={classes.list}>
                        {gateways.filter(i => currentGatewayTypes.includes(i.type)).map(item => (
                            <div key={item.type} className={classes.item}>
                                <div className={classes.itemContent}>
                                    <div className={classes.itemIcon}>
                                        <img src={item.image} alt="" className={classes.icon}/>
                                    </div>
                                    <div className={classes.itemBodySelect}>
                                        <Typography variant="h6">
                                            {item.title}
                                        </Typography>
                                        <span className={classes.status}>Подключено</span>
                                    </div>
                                    <SwitchUI
                                        style={{marginLeft:'auto'}}
                                        color="primary"
                                        onChange={() =>onToggleEnabled(item.type)}
                                        checked={currentGateways.find(gateway => gateway.type === item.type)!.isEnabled}
                                    />
                                </div>
                                <Button onClick={() => handleClick(item.type)} variant="outlined" color="primary"
                                        className={classes.contentBtn}>
                                    Настроить
                                </Button>
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className={classes.empty}>
                        <MenuAddLineIcon className={classes.emptyIcon}/>
                        <Typography align="center">
                            У вас пока нет подключенных шлюзов
                        </Typography>
                    </div>
                )}
            </div>


            <div className={classes.row}>
                <div className={classes.title}>
                    <div className={classes.titleLineLeft}/>
                    <span>Доступные</span>
                    <div className={classes.titleLineRight}/>
                </div>

                {gateways.filter(i => !currentGatewayTypes.includes(i.type)).length > 0 ?  (
                    <div className={classes.list}>
                        {gateways.filter(i => !currentGatewayTypes.includes(i.type)).map(item => (
                            <div key={item.type} className={classes.item}>
                                <div className={classes.itemContent}>
                                    <div className={classes.itemIcon}>
                                        <img src={item.image} alt="" className={classes.icon}/>
                                    </div>
                                    <div className={classes.itemBody}>
                                        <Typography variant="h6">
                                            {item.title}
                                        </Typography>
                                        <Typography variant="body1" style={{
                                            lineHeight: 1.1,
                                            opacity: 0.8
                                        }}>
                                            {item.description}
                                        </Typography>
                                        <Link href={item.site} target='_blank' color='inherit'
                                              variant='body1' underline="always">
                                            {item.site}
                                        </Link>
                                    </div>
                                </div>
                                <Button onClick={() => handleClick(item.type)} variant="outlined" color="primary"
                                        className={classes.contentBtn}>
                                    Подключить
                                </Button>
                            </div>
                        ))}
                </div>) : (
                    <div className={classes.empty}>
                        <MenuAddLineIcon className={classes.emptyIcon}/>
                        <Typography align="center">
                            У вас подключены все шлюзы
                        </Typography>
                    </div>
                )}
            </div>
            <WidgetDisable isOpen={isOpenWidgetDisable} handleClose={() => setIsOpenWidgetDisable(false)} handleSubmit={disableGateway} />
            <WidgetEnable isOpen={isOpenWidgetEnable} handleClose={() => setIsOpenWidgetEnable(false)} handleSubmit={enableGateway} />

        </div>
    )
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => bindActionCreators({
    updateRestaurantInfo
}, dispatch);

const GatewaysIntegrationsPage: FC = () => {
    return (
        <PageContentWrap noPadding fullWidth spacing={0}>
            <Switch>
                <Route exact path={`${Routers.gateways}/:gatewayType`} component={Gateway}/>
                <Route
                    path={Routers.gateways}
                    component={withStyles(styles)(withRouter(
                        connect(mapStateToProps, mapDispatchToProps)(
                            GatewaysContainer
                        )
                    ))}
                />
            </Switch>
        </PageContentWrap>
    )
}

export default GatewaysIntegrationsPage
