import React, {useEffect, useState} from 'react';
import {RouteComponentProps, useHistory, useParams, withRouter} from 'react-router-dom';
import smsAero from "../../../images/smsAero.png";
import {
    Button,
    createStyles,
    IconButton,
    MenuItem,
    Select,
    Switch,
    Typography,
    withStyles,
    WithStyles
} from "@material-ui/core";
import EditField from "../../../components/Common/SelfSavingFields/EditField";
import {EditOutlined as EditIcon} from '@material-ui/icons';
import CopyIcon from "../../../components/Common/Icons/CopyIcon";
import {WidgetDelete} from "../index";
import {connect} from "react-redux";
import {IEvent, IGateway, TEventGateway, TRestaurant, TTypeGateway} from "../../../types/ICabinetStore";
import {IAppStore} from "../../../types/IAppStore";
import {bindActionCreators, Dispatch} from "redux";
import {updateRestaurantInfo} from "../../../actions/restaurantsInfo/RestaurantsInfoActions";
import smsCenter from "../../../images/smsCenter.png";
import playMobile from "../../../images/playMobile.png";
import {useSnackbar} from 'notistack';

interface IMapsToPops {
    currentRestaurant?: TRestaurant;
}

const mapStateToProps = (state: IAppStore): IMapsToPops => {
    return {
        currentRestaurant: state.cabinet.currentRestaurant
    };
};

const styles = createStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: 32,
        margin: 24,
        width: '100%',
    },
    item: {
        padding: 24,
        width: '100%',
        backgroundColor: '#fff',
        borderRadius: 4,
        boxShadow: '0 3px 6px 0 rgba(31, 48, 63, 0.5)',
        '@media (max-width: 800px)': {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
        }
    },
    main: {
        display: 'flex',
        gap: 24,
        '@media (max-width: 800px)': {
            gap: 0
        }
    },
    heading: {
        display: 'flex',
        flexDirection: 'column',
        flex: '0 0 34%',
        '@media (max-width: 800px)': {
            flex: 1
        },
        gap: 24
    },
    headingIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#F7F8FA',
        width: 96,
        height: 96,
        borderRadius: 8,
        border: '1px solid #E7EBEE',
    },
    icon: {
        width: '100%',
        height: 'auto',
        maxWidth: 64,
    },
    buttonDelete: {
        height: 37,
        width: 105,
        fontFamily: 'inherit',
        backgroundColor: '#E7EBEE',
        boxShadow: '0 2px 2px 0 rgba(50, 50, 50, 0.25)',
        '&.active': {
            backgroundColor: '#F45858',
        },
    },
    headingContent: {
      display: 'flex',
      gap: 16,
      alignItems: 'center',
    },
    headingBody: {
        display: 'flex',
        flexDirection: 'column',
        gap: 8
    },
    headingStatus: {
        fontSize: 10,
        textTransform: 'uppercase',
        padding: '4px 8px',
        backgroundColor: '#E7EBEE',
        borderRadius: 16,
        width: 'fit-content',
    },
    buttonStatus: {
        fontSize: 10,
        textTransform: 'uppercase',
        padding: '4px 8px',
        backgroundColor: '#99EDD5',
        color: '#0E4737',
        borderRadius: 16,
        width: 'fit-content',
    },
    body: {
      flex: '1 1 auto',
    },
    divider: {
        width: 1,
        height: '100%',
        backgroundColor: '#323232',
        opacity:0.5
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        gap: 24
    },
    settings: {
        marginTop: 48,
        display: 'flex',
        flexDirection: 'column',
        gap: 32
    },
    buttonSave: {
        height: 37,
        width: 122,
        fontFamily: 'inherit',
        boxShadow: '0 2px 2px 0 rgba(50, 50, 50, 0.25)',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        gap: 16
    },
    data: {
        display: 'flex',
        gap: 16,
        padding: 16,
        backgroundColor: '#E7EBEE',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: 4,
    },
    dataHeader: {
        display: 'flex',
        gap: 16,
        alignItems: 'center',
    },
    dataBody: {
        display: 'flex',
        flexDirection: 'column',
        gap: 16
    },
    buttonCopy: {
        display: 'flex',
        alignItems: 'center',
        gap: 8
    },
    patterns: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 24,
    },
    patternItem: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '24px 0',
        '&:not(:last-child)': {
            borderBottom: '1px solid #DEDEDE',
        },
        '@media (max-width: 800px)': {
            flexDirection: 'column',
        }
    },
    patternItemMessage: {
        flex: '0 0 calc(50% - 24px)'
    },
    patternItemContent: {
        display: 'flex',
        gap: 16,
        alignItems: 'center',
    },
    label: {
        fontSize: 11,
        marginBottom: -28,
        margin: 0,
        color: 'rgba(0, 0, 0, 0.38)',
    }
})

const gateways = {
    'SMS_AERO': {
        title: 'SMS Aero',
        image: smsAero
    },
    'SMSC': {
        title: 'SMS-центр',
        image: smsCenter
    },
    /*'PLAY_MOBILE': {
        title: 'Play Mobile (Uzbekistan)',
        image: playMobile
    },*/
}

const defaultPatterns: IEvent[] = [
    {
        type: 'PHONE_NUMBER_CONFIRMATION',
        template: null,
        isEnabled: true
    },
    {
        type: 'BOOKING_CONFIRMATION',
        template: "<название ресторана>. Ждем вас <дата> в <время>",
        isEnabled: true
    },
    {
        type: 'VISIT_REMINDER',
        template: "<название ресторана>. Готовим Ваш стол к <время>",
        isEnabled: true
    },
    {
        type: 'BOOKING_CANCELLATION',
        template: "<название ресторана>. Ваш резерв <дата> в <время> был отменен",
        isEnabled: true
    },
    {
        type: 'VISIT_COMPLETION',
        template: "<название ресторана>. Спасибо за визит! Ваш счет составил <сумма> руб.",
        isEnabled: false
    }
]

const patternsTitle: Record<TEventGateway, string> = {
    BOOKING_CONFIRMATION:'Подтверждение резерва',
    VISIT_REMINDER:'Напоминание за 1 час до визита',
    BOOKING_CANCELLATION: 'Отмена резерва',
    VISIT_COMPLETION: 'После посещения',
    PHONE_NUMBER_CONFIRMATION: 'Подтверждение номера телефона'
}

interface IDispatchToProps {
    updateRestaurantInfo: typeof updateRestaurantInfo;
}

interface IProps extends WithStyles<typeof styles>, IMapsToPops, RouteComponentProps, IDispatchToProps {}

const Gateway: React.FC<IProps> = ({ classes, currentRestaurant, updateRestaurantInfo }) => {
    const params = useParams<{gatewayType: string}>();
    const history = useHistory();
    const type = params.gatewayType as TTypeGateway
    const [isActive, setIsActive] = useState(false);
    const [currentGateway, setCurrentGateway] = useState<IGateway | null>(null);
    const [isEdit, setIsEdit] = useState(false);
    const [isOpenWidgetDelete, setIsOpenWidgetDelete] = useState(false);
    const [typeMessage, setTypeMessage] = useState('');
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [patterns, setPatterns] = useState<IEvent[]>([]);
    const snackbar = useSnackbar();

    const typesMessage = [
        {
            value: 'Все резервы',
            key: 'ALL_BOOKING',
        },
        {
            value: 'Гостям, пришедшим через виджет',
            key: 'WIDGET_BOOKING',
        }
    ]

    useEffect(() => {
        if (currentRestaurant) {
            setTypeMessage(currentRestaurant.settings.smsNotificationSettings.recipients[0] || 'ALL_BOOKING')
            setPatterns(currentRestaurant.settings.smsNotificationSettings.events.length ? currentRestaurant.settings.smsNotificationSettings.events : defaultPatterns)
            setCurrentGateway(currentRestaurant.settings.smsNotificationSettings.gateways.find(item => item.type === type) || null)
        }
    }, [currentRestaurant]);

    useEffect(() => {
        if (currentGateway) {
            setLogin(currentGateway.principal as string)
            setPassword(currentGateway.credentials as string)
        }
    }, [currentGateway]);

    useEffect(() => {
        if (currentRestaurant) {
            setIsActive(currentRestaurant.settings.smsNotificationSettings.gateways.some(gateway => gateway.type === type))
        }
    }, [currentRestaurant]);

    useEffect(() => {
        if (currentRestaurant && typeMessage && currentRestaurant.settings.smsNotificationSettings.recipients[0] !== typeMessage) {
            // @ts-ignore
            updateRestaurantInfo({ recipients: [typeMessage], currentRestaurant, type: 'smsNotificationSettings' });
        }
    }, [typeMessage]);

    useEffect(() => {
        if (currentRestaurant && patterns.length && JSON.stringify(currentRestaurant.settings.smsNotificationSettings.events) !== JSON.stringify(patterns)) {
            // @ts-ignore
            updateRestaurantInfo({ events: patterns, currentRestaurant, type: 'smsNotificationSettings' });
        }
    }, [patterns])

    useEffect(() => {
        if (!isActive) {
            setIsEdit(true)
        } else {
            setIsEdit(false)
        }
    }, [isActive]);

    const handleSave = () => {
        if (!login || !password || !currentRestaurant) return;

        const currentGateways = currentRestaurant.settings.smsNotificationSettings.gateways;

        const existingGatewayIndex = currentGateways.findIndex(gateway => gateway.type === type);

        if (existingGatewayIndex !== -1) {
            const updatedGateways = currentGateways.map((gateway, index) => {
                if (index === existingGatewayIndex) {
                    return {
                        ...gateway,
                        credentials: password,
                        principal: login,
                    };
                }
                return gateway;
            });
            // @ts-ignore
            updateRestaurantInfo({ gateways: updatedGateways,  currentRestaurant, type: 'smsNotificationSettings' });
        } else {
            const isEnabled =  currentGateways.length === 0
            const newGateway = {
                type: type,
                credentials: password,
                principal: login,
                isEnabled: isEnabled,
            };

            const updatedGateways = [...currentGateways, newGateway];

            // @ts-ignore
            updateRestaurantInfo({ gateways: updatedGateways, isEnabled: isEnabled, currentRestaurant, type: 'smsNotificationSettings' });
        }

        setIsEdit(false);
    }



    const handleCopy = () => {
        navigator.clipboard.writeText(password)
    }

    const handleChangePatternMessage = (value: string, item: any) => {
        setPatterns(prevPatterns =>
            prevPatterns.map(pattern =>
                pattern.type === item.type ? { ...pattern, template: value } : pattern
            )
        );
    };

    const handleChangePatternValue = (item: any) => {
        setPatterns(prevPatterns =>
            prevPatterns.map(pattern =>
                pattern.type === item.type ? { ...pattern, isEnabled: !pattern.isEnabled } : pattern
            )
        );
    };


    const handleDelete = () => {
        if (!currentGateway || !currentRestaurant) return;

        const currentGateways = currentRestaurant.settings.smsNotificationSettings.gateways;
        const existingGatewayIndex = currentGateways.findIndex(gateway => gateway.type === currentGateway.type);

        if (existingGatewayIndex !== -1) {
            const updatedGateways = currentGateways.filter((_, index) => index !== existingGatewayIndex);
            const isEnabled = updatedGateways.filter(item => !item.isEnabled).length !== updatedGateways.length;

            updateRestaurantInfo({
                isEnabled: isEnabled,
                gateways: updatedGateways,
                currentRestaurant,
                type: 'smsNotificationSettings'
            });
            setCurrentGateway(null);
            setLogin('')
            setPassword('')
        }
        history.push('/gateways')
    }

    const validateTemplate = (template: string) => {
        const variablePattern = /<([^>]+)>/g;
        const validVariables = ['название ресторана', 'дата', 'время', 'сумма', 'имя'];
        const foundVariables = new Set();
        let match;
        while ((match = variablePattern.exec(template)) !== null) {
            foundVariables.add(match[1]);
        }

        // @ts-ignore
        for (const variable of foundVariables) {
            if (!validVariables.includes(variable)) {
                return false;
            }
        }

        return true;
    };


    return (
        <div className={classes.container}>
            <div className={`${classes.item} ${classes.main}`}>
                <div className={classes.heading}>
                    <div className={classes.headingContent}>
                        <div className={classes.headingIcon}>
                            <img src={gateways[type].image} alt="" className={classes.icon}/>
                        </div>

                        <div className={classes.headingBody}>
                            <Typography variant="h6">{gateways[type].title}</Typography>
                            <span className={classes.headingStatus}>{!isActive && 'НЕ'} НАСТРОЕНО</span>
                        </div>
                    </div>

                    <Button variant="contained" color="inherit" disabled={!isActive} className={`${classes.buttonDelete} ${isActive  && 'active'}`} onClick={() => setIsOpenWidgetDelete(true)}>
                        Удалить
                    </Button>
                </div>
                <div className={classes.divider}/>
                <div className={classes.body}>
                    <div className={classes.content}>
                        <Typography variant="h6">Параметры интеграции</Typography>
                        {isEdit ?
                            <>
                                <div className={classes.form}>
                                    <Typography variant="body1" color='secondary'>
                                        Для подключения сервиса, заполните форму
                                        ниже и нажмите кнопку “Сохранить”
                                    </Typography>
                                    <EditField
                                        label={type === 'SMS_AERO' ? 'Email' : 'Логин'}
                                        value={login}
                                        handleChangeList={(event) => setLogin(event.value)}
                                        type='text'
                                    />
                                    <EditField
                                        label={type === 'SMS_AERO' ? 'Ключ API' : 'Пароль'}
                                        value={password}
                                        handleChangeList={(event) => setPassword(event.value)}
                                        type='text'
                                    />
                                </div>
                                <Button color='primary' onClick={handleSave} variant="contained" className={classes.buttonSave}>
                                    Сохранить
                                </Button>
                            </>
                            :
                            <div className={classes.data}>
                                <div className={classes.dataBody}>
                                    <div className={classes.dataHeader}>
                                        <Typography variant='subtitle2'>Данные авторизации</Typography>
                                        {currentGateway && currentGateway!.isEnabled && (
                                            <div className={classes.buttonStatus}>
                                                Подключено
                                            </div>
                                        )}
                                    </div>

                                    <div className={classes.form}>
                                        <Typography variant='body1'>{type === 'SMS_AERO' ? 'Email' : 'Логин'}: {login}</Typography>
                                        <div className={classes.buttonCopy}>
                                            <Typography variant='body1'>{type === 'SMS_AERO' ? 'Ключ API' : 'Пароль'}: {password} </Typography>
                                            <CopyIcon onClick={handleCopy} style={{cursor: 'pointer'}} />
                                        </div>
                                    </div>
                                </div>

                                <IconButton onClick={() => setIsEdit(true)}> <EditIcon/> </IconButton>
                            </div>
                        }
                    </div>


                    <div className={classes.settings}>
                        <Typography variant="h6" color='secondary'>Настройки</Typography>
                        <span className={classes.label}>
                            Отправка сообщений
                        </span>
                        <Select
                            value={typeMessage}
                            label='Отправка сообщений'
                            disabled={isEdit}
                            name="start"
                            onChange={e => setTypeMessage(e.target.value as string)}
                        >
                            {typesMessage.map(item => (
                                <MenuItem key={item.key} value={item.key}>{item.value}</MenuItem>))}
                        </Select>
                    </div>

                </div>

            </div>
            <div className={classes.item}>
                <Typography variant="h6" color={isEdit ? 'secondary' : 'textPrimary'}>Шаблоны сообщений</Typography>
                {isEdit ?
                    <Typography variant="body1" color='secondary' style={{marginTop: 24}}>
                        Для редактирования сообщений, настройте параметры интеграции выше
                    </Typography>
                :
                    <div>
                        <Typography variant="body1" color='textPrimary' style={{ marginTop: 8 }}>
                            Вы можете задавать переменные в текстах сообщений: <br/>
                            <span style={{ fontWeight: 'bold' }}> &lt;дата&gt;</span> - дата резерва,
                            <span style={{ fontWeight: 'bold' }}> &lt;время&gt;</span> - время резерва,
                            <span style={{ fontWeight: 'bold' }}> &lt;сумма&gt;</span> - сумма чека,
                            <span style={{ fontWeight: 'bold' }}> &lt;название ресторана&gt;</span> - название ресторана,
                            <span style={{ fontWeight: 'bold' }}> &lt;имя&gt;</span> - имя и фамилия
                        </Typography>

                        <div className={classes.patterns}>
                            {patterns.map(item => (
                                <div key={item.type} className={classes.patternItem}>
                                    <div className={classes.patternItemContent}>
                                        <Switch
                                            color="primary"
                                            checked={item.isEnabled}
                                            onChange={() => handleChangePatternValue(item)}
                                        />

                                        <Typography variant="subtitle2" style={{fontSize: 16}}>
                                            {patternsTitle[item.type as TEventGateway]}
                                        </Typography>
                                    </div>


                                    {!!item.template && (
                                        <div className={classes.patternItemMessage}>
                                            <EditField
                                                label='Текст сообщения'
                                                value={item.template}
                                                handleChangeList={(event) => {
                                                   if (validateTemplate(event.value)) {
                                                       handleChangePatternMessage(event.value, item)
                                                   } else {
                                                       snackbar.enqueueSnackbar(`Ошибка сохранения. В шаблоне сообщения '${patternsTitle[item.type as TEventGateway]}' неверно указана переменная`, {
                                                           anchorOrigin: {vertical: 'bottom', horizontal: 'center'},
                                                           variant: 'error',
                                                       })
                                                   }
                                                }}
                                                type='text'
                                            />
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                }
            </div>
            <WidgetDelete isOpen={isOpenWidgetDelete} handleClose={() => setIsOpenWidgetDelete(false)} handleDelete={handleDelete} />
        </div>
    );
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => bindActionCreators({
    updateRestaurantInfo
}, dispatch);


export default withStyles(styles)(withRouter(
    connect(mapStateToProps, mapDispatchToProps)(
        Gateway
    )
))
