import React, {Fragment} from 'react';
import wrapWithPath from './wrapSvgPath';

const MenuAddLineIcon = (
    <Fragment>
            <path
                d="M36 30L35.998 36H42V40H35.998L36 46H32L31.998 40H26V36H31.998L32 30H36ZM22 36V40H6V36H22ZM42 22V26H6V22H42ZM42 8V12H6V8H42Z"
                fill="#323232" fillOpacity="0.16"/>

    </Fragment>
);

export default wrapWithPath(MenuAddLineIcon, '0 0 48 48');
